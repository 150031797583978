import Seo from 'src/components/seo/Seo'
import LifetimeWarranty from 'src/components/servicos/ServicesPage/GarantiaVitalicia'

function Page() {
  return (
    <>
      <Seo title="Garantia vitalícia | Decathlon" />
      <LifetimeWarranty />
    </>
  )
}

export default Page
